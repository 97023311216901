import "./App.css";

import hillLeft from "./img/final/01/01_A.png";
import hillRight from "./img/final/01/01_B.png";
import badgerFam from "./img/final/01/01_C.png";

import sittingBadger from "./img/final/02/02_A.png";
import whiteBoard from "./img/final/02/02_B.png";
import rocketBadgers from "./img/final/02/02_C.png";

import rocket from "./img/final/03/rocket.png";
import walkingBadger from "./img/final/02/03_A.png";
import standingBadger from "./img/final/02/04_B.png";

import skyBox from "./img/final/01/01_background.jpg";
import back1 from "./img/final/02/backgrounds/back1.jpg";
import back2 from "./img/final/02/backgrounds/back2.png";
import back3 from "./img/final/02/backgrounds/back3.png";
import back4 from "./img/final/02/backgrounds/back4.png";

import controlArrow from "./img/control-arrow.png";

const LabScene = ({ sceneNo }) => {
  return (
    <div>
      <div className="lab-background" id={`lab-background-${sceneNo}`}>
        <img
          className="back1 normal preload"
          id={`back1-${sceneNo}`}
          src={back1}
        />
        <img
          className="back2 elongated preload"
          id={`back2-${sceneNo}`}
          src={back2}
        />
        <img
          className="back3 fadeInBack preload"
          id={`back3-${sceneNo}`}
          src={back3}
        />
        <img
          className="back4 streched preload"
          id={`back4-${sceneNo}`}
          src={back4}
        />

        {(sceneNo === 2 || sceneNo === 3) && (
          <>
            <div className="rocket" id={`rocket-${sceneNo}`}>
              <img
                data-depth="0.2"
                className="preload parallaxScene2"
                src={rocket}
              />
            </div>

            <div className="walking-badger" id={`walking-badger-${sceneNo}`}>
              <img
                data-depth="0.8"
                className="preload parallaxScene2"
                src={walkingBadger}
              />
            </div>

            <div className="standing-badger preload">
              <img
                data-depth="0.6"
                src={standingBadger}
                className="parallaxScene2"
              />
            </div>
          </>
        )}
      </div>
      <div className="lab-scene" id={`lab-scene-${sceneNo}`}>
        <img
          className="sitting-badger preload"
          id={`sitting-badger-${sceneNo}`}
          src={sittingBadger}
        />
        <img className="whiteboard preload" src={whiteBoard} />
        <img className="badgers-building-rockets preload" src={rocketBadgers} />
      </div>
    </div>
  );
};

function App() {
  return (
    <div id="intialized-holiday-card">
      <div className="preloader">
        <div className="preloader-inner">
          <div className="logo" />
          <div className="loading">
            Loading
            <span className="loading-dot">.</span>
            <span className="loading-dot">.</span>
            <span className="loading-dot">.</span>
          </div>
        </div>

        <div className="copyright">
          © 2020 Initialized Capital Management. Honeybadger logo, "ic()", and
          "initialized()" wordmark are all trademarks of Initialized Capital
          Management. All Rights Reserved.
        </div>
      </div>
      <div className="logo fadeInMenu" />

      <div id="controls" className="controls">
        <div className="control-arrow up" id="control-down">
          <img src={controlArrow} />
        </div>
        <div className="spacer">scroll</div>
        <div className="control-arrow down active" id="control-up">
          <img src={controlArrow} />
        </div>
      </div>

      <div className="panel scene1 first" id="hills-container">
        <div className="hills-inner fadeIn" id="hills-container-inner">
          <div className="left-hill-wrapper">
            <img
              src={hillLeft}
              className="left-hill parallaxScene1 preload"
              id="left-hill"
              data-depth="0.1"
            />
          </div>

          <div className="right-hill-wrapper" id="right-hill">
            <div
              className="right-hill parallaxScene1"
              data-depth="0.4"
              id="right-hill-inner"
            >
              <div className="zoom-point" id="zoom-point"></div>
              <img className="hill-right-image preload" src={hillRight} />
            </div>

            <div className="badger-fam-wrapper">
              <img
                src={badgerFam}
                id="badger-fam"
                className="badger-fam parallaxScene1 preload"
                data-depth="0.8"
              />
            </div>
          </div>
        </div>
        <LabScene sceneNo={1} />

        <div className="lab-text initial">
          Times of great change bring with them even greater possibilities.
        </div>
      </div>

      <div className="panel scene3" id="pan-container">
        <div className="lab-inner" id="pan-inner">
          <LabScene sceneNo={3} />

          <div className="lab-text">
            Times of great change bring with them even greater possibilities.
          </div>

          <div className="lab-text-final">
            Warmest wishes <br />
            for a safe, healthy,
            <br />
            and prosperous 2021 <br />
            filled with big dreams <br />
            and even bigger <br />
            accomplishments! <br />
          </div>
        </div>
      </div>
      <img className="preload" src={skyBox} style={{ display: "none" }} />
    </div>
  );
}

export default App;
